import React, { FC, useState } from 'react';
import GoogleMapReact from "google-map-react";
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../components/BreadCrumb';

interface LocationState {
  type: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  title: string;
}

interface MarkerProps {
  lat: number;
  lng: number;
};

const Marker: React.FC<MarkerProps> = ({ lat, lng }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isValidCoordinate = 
    !isNaN(lat) && lat >= -90 && lat <= 90 &&
    !isNaN(lng) && lng >= -180 && lng <= 180;

  if (!isValidCoordinate) {
    return (
      <div style={{ color: 'red', fontWeight: 'bold' }}>
        ⚠️ Invalid coordinates
      </div>
    );
  }
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: "relative",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: 1,
      }}
    >
      {/* Custom SVG Marker */}
      <svg
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="red"
        stroke="#fff"
        strokeWidth="2"
      >
        <path d="M12 0C7.31 0 3.5 3.81 3.5 8.5c0 4.69 7.5 15.5 8.5 15.5s8.5-10.81 8.5-15.5c0-4.69-3.81-8.5-8.5-8.5zm0 13c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
      </svg>
       
      {isHovered && (
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "8px 12px",
            borderRadius: "4px",
            fontSize: "14px",
            whiteSpace: "nowrap",
            boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
          }}
        >
          Lat: {lat}, Lng: {lng}
        </div>
      )}
    </div>
  );
};

const Index: FC = () => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const location = useLocation();
  const { integrationType, integrationId, country, countryId, city, cityId, airportIATA, airportId } =
  useParams();
  const { coordinates } = location.state as LocationState;

  if (!apiKey) return <div>Loading map...</div>;
  
  // Convert to numbers and validate
  const lat = Number(coordinates?.lat);
  const lng = Number(coordinates?.lng);
  console.log("++++++++++++++++++", coordinates?.lat, coordinates?.lng);
  const paths = [
    {
      name: `Auto Driver Location`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/auto-driver-event`,
    },
  ];

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Auto Driver Events",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/auto-driver-event`,
    },
    {
      name: "Auto Driver Location",
      //  url:""
    },
  ];
  const isValidCoords = 
    !isNaN(lat) && lat >= -90 && lat <= 90 &&
    !isNaN(lng) && lng >= -180 && lng <= 180;

  return (
    <>
    <div>

    <Breadcrumb paths={paths} />
    <div className="breadcrumb-description">
              Need this section to set up Auto events for each location
            </div>
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
    </div>
    <div className="edit-location-zone-map-section">
                        {/* form */}
                        <div className="bg-white main-section-spacing border-radius-8 zone-map-section-form">
    <div style={{ 
     height: "487px",
      width: '100%',
      marginTop: 150,
      position: 'fixed',
      top: 50,
      left: 0,
      zIndex: 1,
    }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={{ lat, lng }}
        zoom={15}
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeControl: true,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "on" }]
            }
          ]
        }}
      >
        {isValidCoords && <Marker lat={lat} lng={lng} />}
      </GoogleMapReact>
    </div>
    </div>
    </div>
    </>
  )
}

export default Index;