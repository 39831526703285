import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import { floor } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import moment from "moment";
import SinglePaymentDetails from "./SinglePaymentDetails";
import ThunkPayment from "../../../redux/actions/thunks/paymant";
import { CircularProgress, Box, NativeSelect } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { pdfjs, Document, Page } from "react-pdf";
import {
  getMonday,
  generateinvoiceNumber,
  generateReceiptNumber,
} from "../../../helpers/index";
import { formatCurrency } from "../../../helpers/getSymbolFromCurrency";
import Title from "./Title";
import SinglePayInDetails from "./SinglePayInDetails";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import JSZip from "jszip";
import { PaymentInvoiceGenerate } from "../../../helpers/PaymentInvoiceGenerate";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import getSymbolFromCurrency from "currency-symbol-map";
import { checkPaymentInvoiceValidation } from "../../../helpers/checkPaymentInvoiceValidation";
const XlsxPopulate = require("xlsx-populate");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #4b4b4b",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
  },
  ul: {
    backgroundColor: "inherit",
  },
  root_Tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  //accordian
  root_accordian: {
    width: "100%",
    backgroundColor: "red",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  counts: {
    border: "1px solid black",
    padding: 10,
  },
}));
let allSelected = [];
function PaymentDetails({
  // loading,
  error,
  user,
  GetPayouts,
  CreatePayment,
  GetPaymentByYear,
  GetPayments,
  GetPaidPaymentsByTimeRange,
  SearchByBookingId,
  PreviewPayment,
  imageBase64,
  XMLGenerated,
  GetPayins,
  GetPayinPayments,
  GetPaidPaymentByYear,
  CreatePayinPayment,
  PaymentInvoiceDetail,
  UpdatePayinPayment,
  PreviewPayinPayment,
}) {
  const [type, setType] = useState("payouts");
  const [typeStandbyData, setTypeStandbydata] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState(null);
  const [paymentSelected, setPaymentSelected] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [driverId, setDriverId] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(null);
  const [count, setCount] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchTextById, setSearchTextById] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfString, setPdfString] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(null);

  const [paidFromDate, setPainFromDate] = useState(null);
  const [paidTillDate, setPaidTillDate] = useState(null);
  const [loadingProcess, setLoadingProcess] = useState({
    type: null,
    loading: false,
  });
  const [searchData, setSearchData] = useState({
    payins: false,
    pending: false,
    payins_paid: false,
    paid: false,
    payouts: false,
  });
  const [payinData, setPayinData] = useState([]);
  const [clientId, setClientId] = useState(null);

  const [selectedRecords, setSelectedRecords] = useState([]);

  const [bank, setBank] = useState("");
  const [currency, setCurrency] = useState("all");
  const [country, setCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPdfString("");
  };

  /*const handleDownloadPdf = () => {
    if (pdfString) {
      const file = new Blob([pdfString], {type: 'application/pdf'});
      const pdfURL = URL.createObjectURL(file);
      let alink = document.createElement('a');
      alink.id = "download-pdf";
      alink.target = "_blank";
      alink.href = pdfURL;
      alink.download = 'SamplePDF.pdf';
      alink.click();
    }
  }*/

  useEffect(() => {
    (async () => {
      if (type == "paid") {
        const { count, rows } = await GetPayments(page, limit, searchText);
        // setData(rows)
        setRowsFunction(rows);
        countTotalPage(count);
      } else if (type === "pending") {
        const { count, rows } = await GetPayinPayments(false, searchText);
        setData(rows);
        countTotalPage(count);
      } else if (type === "payins_paid") {
        const { count, rows } = await GetPayinPayments(true, searchText);
        setData(rows);
        countTotalPage(count);
      }
    })();
  }, [searchText]);

  // const getWeekRange = async () => {
  //   setLoading(true);
  //   const lastDate = "2022-12-03";
  //   let today = moment(new Date());
  //   let finalWeeks = [];
  //   let numb = 1;

  //   while (moment(today).isAfter(lastDate)) {
  //     const dayOfWeek = parseInt(today.day());

  //     const sundayDate = today;

  //     const mondayDate = moment(today).subtract(getMonday[dayOfWeek], "day");

  //     const monday = mondayDate.format("YYYY-MM-DD");
  //     const sunday = sundayDate.format("YYYY-MM-DD");

  //     finalWeeks.push(`${monday} - ${sunday}`);

  //     if (numb == 2) {
  //       if (type === "payouts") {
  //         let payoutData = await GetPayouts(
  //           user.id,
  //           `${mondayDate.format("YYYY-MM-DD")} - ${sundayDate.format(
  //             "YYYY-MM-DD"
  //           )}`,
  //           moment().utcOffset()
  //         );
  //         setData(payoutData);
  //       } else {
  //         let payinsData = await GetPayins(
  //           user.id,
  //           `${mondayDate.format("YYYY-MM-DD")} - ${sundayDate.format(
  //             "YYYY-MM-DD"
  //           )}`
  //         );
  //         console.log("....................", payinsData);
  //         setData(payinsData);
  //       }
  //       setSelectedWeek(`${monday} - ${sunday}`);
  //       setSearchTextById("");
  //     }

  //     today = moment(mondayDate.subtract(1, "day"));
  //     numb += 1;
  //   }
  //   setWeeks(finalWeeks);
  //   setLoading(false);
  // };
  const getWeekRange = async () => {
    setLoading(true);
    const lastDate = moment("2022-12-03", "YYYY-MM-DD");
    let today = moment(new Date());
    let finalWeeks = [];
    let numb = 1;

    while (today.isSameOrAfter(lastDate)) {
      // Calculate the ISO week start (Monday) and end (Sunday)
      const mondayDate = moment(today).startOf("isoWeek");
      const sundayDate = moment(today).endOf("isoWeek");

      const monday = mondayDate.format("YYYY-MM-DD");
      const sunday = sundayDate.format("YYYY-MM-DD");

      finalWeeks.push(`${monday} - ${sunday}`);

      if (numb === 2) {
        if (type === "payouts") {
          const payoutData = await GetPayouts(
            user.id,
            `${monday} - ${sunday}`,
            moment().utcOffset()
          );
          setData(payoutData);
        } else {
          const payinsData = await GetPayins(user.id, `${monday} - ${sunday}`  );
          console.log("....................", payinsData);
          setData(payinsData);
        }
        setSelectedWeek(`${monday} - ${sunday}`);
        setSearchTextById("");
      }

      // Move to the previous week
      today = mondayDate.subtract(1, "week");
      numb += 1;
    }

    setWeeks(finalWeeks);
    setLoading(false);
  };

  useEffect(() => {
    setType("payouts");
  }, []);

  const loadData = async (isfromPayin = false) => {
    if (searchTextById.length > 0) {
      await SearchByID(isfromPayin);
    } else {
      console.log(type);
      if (type == "payouts") {
        let payoutData = await GetPayouts(
          user.id,
          selectedWeek,
          moment().utcOffset()
        );
        setData(payoutData);
      } else {
        let payinData = await GetPayins(user.id, selectedWeek  );
        setData(payinData);
      }
    }
    // if (pageNumber) {
    //   setCurrentPage(pageNumber)
    // }
  };

  useEffect(() => {
    (async () => {
      if (type == "paid" && searchTextById.length == 0) {
        const { count, rows } = await GetPayments(page, limit, searchText);
        setRowsFunction(rows);
        // setData(rows)
        countTotalPage(count);
      } else if (type == "payouts" && searchTextById.length == 0) {
        await getWeekRange();
      } else if (type === "payins" && searchTextById.length == 0) {
        await getWeekRange();
      } else if (type === "pending" && searchTextById.length == 0) {
        const { count, rows } = await GetPayinPayments(false, searchText);
        setData(rows);
        countTotalPage(count);
      } else if (type === "payins_paid" && searchTextById.length == 0) {
        const { count, rows } = await GetPayinPayments(true, searchText);
        setData(rows);
        countTotalPage(count);
      }
    })();
  }, [type]);

  const onpageChange = async (selectedPage) => {
    const { count, rows } = await GetPayments(selectedPage, limit, searchText);
    setRowsFunction(rows);
    // setData(rows)
    countTotalPage(count);
  };

  const countTotalPage = (count) => {
    setCount(count);
    if (parseInt(count) % parseInt(limit) !== 0) {
      setTotal(floor(parseInt(count) / parseInt(limit)) + 1);
    } else {
      setTotal(parseInt(count) / parseInt(limit));
    }
  };

  const columnsPaid = [
    {
      title: "Valid Credentials",
      render: (rowData) => {
        let check = false;
        if (country === "GB") {
          check = checkPaymentInvoiceValidation(rowData, country, bank);
        } else {
          check =
            !!rowData?.user?.IBAN &&
            !!rowData?.user?.bankAccountHolderName &&
            rowData.amount > 0;
        }

        // const check =
        //   !!rowData?.user?.IBAN &&
        //   !!rowData?.user?.bankAccountHolderName &&
        //   rowData.amount > 0;
        rowData.accCheck = check ? true : false;
        return check ? (
          <CheckCircle style={{ color: "green" }} />
        ) : (
          <Cancel style={{ color: "red" }} />
        );
      },
    },
    {
      title: "Invoice",
      field: "invoiceNo",
    },
    {
      title: "Date Approved",
      field: "createdAt",
      render: (rowData) => {
        return <>{moment(rowData.createdAt).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: "Time Approved",
      field: "createdAt",
      render: (rowData) => {
        return <>{moment(rowData.createdAt).format("HH:mm")}</>;
      },
    },
    {
      title: "Driver Display Name",
      field: "user.displayName",
    },
    {
      title: "Company Name",
      field: "user.companyName",
    },
    {
      title: "Total Ride",
      field: "totalRide",
    },
    {
      title: "Amount",
      // field: "amount",
      render: (rowData) => {
        setSelectedCurrency(rowData?.currency);
        return <>{formatCurrency(rowData?.currency, rowData?.amount)}</>;
        // return (
        //   <>
        //     {getSymbolFromCurrency(rowData?.currency || "EUR")}
        //     {parseFloat(rowData?.amount).toFixed(2)}
        //   </>
        // );
      },
    },
    {
      title: "Action",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
      },
      sort: false,
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* {rowData?.pdfURL &&
            <>
            <Button style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }} size="sm" onClick={async () => {
                let a1link = document.createElement('a');
                a1link.id = "view" + rowData.id;
                a1link.href = rowData.pdfURL;
                a1link.target = "_blank";
                a1link.download = `${rowData.invoiceNo}.pdf`;
                a1link.click();
              }}>
                <VisibilityIcon />
              </Button>

              <Button style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }} size="sm" onClick={async () => {
                let alink1 = document.createElement('a');
                alink1.id = "download" + rowData.id;
                alink1.href = `${process.env.REACT_APP_API_BASE_URL}/payment/download/${rowData.id}`;
                alink1.download = `${rowData.invoiceNo}.pdf`;
                alink1.click();
              }}>
                <FileDownloadIcon />
              </Button>
            </>
           } */}

              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={async () => {
                  try {
                    setLoadingProcess({
                      id: rowData?.id,
                      type: "paid_preview",
                      loading: true,
                    });

                    const result = await PaymentInvoiceDetail(
                      rowData?.invoiceNo
                    );
                    let url = null;
                    if (result?.org_image?.Body?.data) {
                      const buffer = new Uint8Array(result?.org_image.Body.data)
                        .buffer; // Represents the string "hello"
                      const blob = new Blob([buffer], {
                        type: "application/octet-stream",
                      });
                      url = URL.createObjectURL(blob);
                    }

                    const pdfUrl = await PaymentInvoiceGenerate(
                      result.invoice_data,
                      false,
                      url,
                      rowData.currency,
                      "blob"
                    );
                    if (pdfUrl) {
                      const newTab = window.open(pdfUrl, "_blank");
                      newTab.focus();
                    }
                  } catch (error) {
                    console.log("error", error);
                  } finally {
                    setLoadingProcess({
                      type: null,
                      loading: false,
                    });
                  }
                }}
              >
                {rowData?.id === loadingProcess?.id &&
                loadingProcess.type === "paid_preview" &&
                loadingProcess.loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <VisibilityIcon />
                )}
                {/* <VisibilityIcon /> */}
              </Button>

              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={async () => {
                  try {
                    setLoadingProcess({
                      id: rowData?.id,
                      type: "paid_invoice_download",
                      loading: true,
                    });

                    const result = await PaymentInvoiceDetail(
                      rowData?.invoiceNo
                    );
                    let url = null;
                    if (result?.org_image?.Body?.data) {
                      const buffer = new Uint8Array(result?.org_image.Body.data)
                        .buffer; // Represents the string "hello"
                      const blob = new Blob([buffer], {
                        type: "application/octet-stream",
                      });
                      url = URL.createObjectURL(blob);
                    }
                    PaymentInvoiceGenerate(
                      result?.invoice_data,
                      true,
                      url,
                      rowData.currency
                    );
                  } catch (error) {
                    console.log("error", error);
                  } finally {
                    setLoadingProcess({
                      type: null,
                      loading: false,
                    });
                  }
                }}
              >
                {rowData?.id === loadingProcess?.id &&
                loadingProcess.type === "paid_invoice_download" &&
                loadingProcess.loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <FileDownloadIcon />
                )}
                {/* <FileDownloadIcon /> */}
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const columns = [
    {
      title: "Week Nr",
      render: (rowData) => {
        return (
          <>{moment(rowData?.weekRange?.split(" - ")[0], "YYYYMMDD").week()}</>
        );
      },
    },
    {
      title: "Week",
      field: "weekRange",
      render: (rowData) => {
        return (
          <>
            {moment(rowData?.weekRange?.split(" - ")[0]).format("DD-MM-YYYY")} -{" "}
            {moment(rowData?.weekRange?.split(" - ")[1]).format("DD-MM-YYYY")}{" "}
          </>
        );
      },
    },
    {
      title: "Display Name",
      field: "driver.displayName",
    },
    {
      title: "Company Name",
      field: "driver.companyName",
    },
    {
      title: "IBAN",
      field: "driver.IBAN",
    },
    {
      title: "Bank Account holder Name",
      field: "driver.bankAccountHolderName",
    },
    {
      title: "Total Ride",
      field: "totalRide",
    },
    {
      title: "Total Amount",
      // field: "totalPrice",
      render: (rowData) => {
        return <>{formatCurrency(rowData?.currency, rowData?.totalPrice)}</>;
      },
      // render: (rowData) => {
      //   return (
      //     <>
      //       {getSymbolFromCurrency(rowData?.currency || "EUR")}
      //       {rowData?.totalPrice?.toFixed(2)}
      //     </>
      //   );
      // },
    },
    {
      title: "Action",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
      },
      sort: false,
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={() => {
                  setOpen(true);
                  let obj = {
                    isPaid: true,
                    amount: rowData.totalPrice,
                    weekStart: rowData.weekRange.split(" - ")[0],
                    weekEnd: rowData.weekRange.split(" - ")[1],
                    invoiceNo: "Concept",
                    totalRide: rowData.totalRide,
                    userId: rowData.driver.id,
                    orgId: user.id,
                    currency: rowData.currency,
                  };

                  PreviewPayment(obj).then((res) => setPdfString(res));
                }}
              >
                <VisibilityIcon style={{ cursor: "pointer" }} />
              </Button>
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to approve this payment?"
                    )
                  ) {
                    try {
                      setLoader(true);
                      // create func for creating invoice number
                      let invoiceNo = await generateinvoiceNumber(
                        user,
                        GetPaymentByYear,
                        rowData.weekRange.split(" - ")[1]
                      );
  
                      if (!invoiceNo) {
                        throw new Error("Invoice number generation failed.");
                      }
                      const bulkObj = {
                        ids: rowData.userBookingIds,
                        obj: {
                          invoiceNo: invoiceNo,
                        },
                      };
                      const bulkAdditionalObj = {
                        ids: rowData.userAdditionaBookingIds,
                        obj: {
                          invoiceNo: invoiceNo,
                        },
                      };
                      let obj = {
                        isPaid: true,
                        amount: rowData.totalPrice,
                        weekStart: rowData.weekRange.split(" - ")[0],
                        weekEnd: rowData.weekRange.split(" - ")[1],
                        invoiceNo,
                        totalRide: rowData.totalRide,
                        userId: rowData.driver.id,
                        orgId: user.id,
                        bulkObj: bulkObj,
                        bulkAdditionalObj: bulkAdditionalObj,
                        currency: rowData.currency,
                      };
                      await CreatePayment(obj);
                      let payoutData = await GetPayouts(
                        user.id,
                        `${moment(selectedWeek.split(" - ")[0]).format(
                          "YYYY-MM-DD"
                        )} - ${moment(selectedWeek.split(" - ")[1]).format(
                          "YYYY-MM-DD"
                        )}`,
                        moment().utcOffset()
                      );
                      setData(payoutData);
                      setLoader(false);
                    } catch (error) {
                      alert("Payment approval failed. Please try again.");
                    } finally {
                      setLoader(false);
                    }
               
                  }
                }}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "green",
                    backgroundColor: "white",
                  }}
                />
              </Button>
              <Button
                style={{ borderRadius: 25, minWidth: 40 }}
                size="sm"
                onClick={async () => {
                  setSelectedRow(rowData);
                  setPaymentSelected(true);
                  setDriverId(rowData.driver.id);
                }}
              >
                <EditIcon />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const columnsPayin = [
    {
      title: "Receipt",
      field: "receiptNo",
    },
    {
      title: "Date Approved",
      field: "createdAt",
      render: (rowData) => {
        return <>{moment(rowData.createdAt).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: "Time Approved",
      field: "createdAt",
      render: (rowData) => {
        return <>{moment(rowData.createdAt).format("HH:mm")}</>;
      },
    },
    {
      title: "Client Display Name",
      render: (rowData) => {
        return (
          <>{rowData?.client?.firstName + " " + rowData?.client?.lastName}</>
        );
      },
    },
    {
      title: "Company Name",
      field: "client.companyName",
    },
    {
      title: "Total Ride",
      field: "totalRide",
    },
    {
      title: "Amount",
      // field: "amount",
      render: (rowData) => {
        return <>{formatCurrency(rowData?.currency, rowData?.amount)}</>;
        // return (
        //   <>
        //     {getSymbolFromCurrency(rowData?.currency || "EUR")}
        //     {parseFloat(rowData?.amount).toFixed(2)}
        //   </>
        // );
      },
    },
    {
      title: "Vat",
      field: "vat",
      render: (rowData) => {
        return <>{formatCurrency(rowData?.currency, rowData?.vat)}</>;
        // return (
        //   <>
        //     {getSymbolFromCurrency(rowData?.currency || "EUR")}
        //     {rowData?.vat?.toFixed(2)}
        //   </>
        // );
      },
    },
    {
      title: "Action",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
      },
      sort: false,
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={async () => {
                  if (rowData.pdfURL) {
                    const isHTMLContent = /<html|<body|<!DOCTYPE html>/i.test(
                      rowData.pdfURL
                    );
                    if (isHTMLContent) {
    
                      setOpen(true);
                      let obj = {
                        mode: "Payin_Padding",
                        htmlContent: rowData.pdfURL,
                        currency: rowData?.currency
                      };
                      PreviewPayinPayment(obj, moment().utcOffset()).then(
                        (res) => setPdfString(res)
                      );
                    } else {
                      let a1link = document.createElement("a");
                      a1link.id = "view" + rowData.id;
                      a1link.href = rowData.pdfURL;
                      a1link.target = "_blank";
                      a1link.download = `${rowData.receiptNo}.pdf`;
                      a1link.click();
                    }
                  } else {
                    alert(
                      "Pdf Content no found! \nYou can contact with Engineering Team for this"
                    );
                  }
                }}
              >
                <VisibilityIcon />
              </Button>

              {type !== "payins_paid" && (
                <Button
                  style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                  size="sm"
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to approve this payment?"
                      )
                    ) {
                      setLoader(true);
                      console.log(rowData.id);
                      await UpdatePayinPayment(rowData.id);

                      const { count, rows } = await GetPayinPayments(
                        false,
                        searchText
                      );
                      setData(rows);
                      countTotalPage(count);
                      setLoader(false);
                    }
                  }}
                >
                  <CheckCircleRoundedIcon
                    sx={{
                      color: "green",
                      backgroundColor: "white",
                    }}
                  />
                </Button>
              )}
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                // onClick={async () => {
                //   let alink1 = document.createElement("a");
                //   alink1.id = "download" + rowData.id;
                //   alink1.href = `${process.env.REACT_APP_API_BASE_URL}/payment/payin/download/${rowData.id}`;
                //   alink1.download = `${rowData.receiptNo}.pdf`;
                //   alink1.click();

                // }}

                onClick={async () => {
                  if (rowData.pdfURL) {
                    const isHTMLContent = /<html|<body|<!DOCTYPE html>/i.test(
                      rowData.pdfURL
                    );
                    if (isHTMLContent) {
                      try {
                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = rowData.pdfURL;
                        tempDiv.style.position = "absolute";
                        tempDiv.style.top = "-9999px"; // Hide it off-screen

                        const imgElement = tempDiv.querySelector("img");
                        if (imgElement) {
                          const base64ImageLogo = await imageBase64({
                            url: imgElement.src,
                          });
                          imgElement.src = base64ImageLogo.image;

                          imgElement.onload = async () => {
                            document.body.appendChild(tempDiv);

                            const canvas = await html2canvas(tempDiv, {
                              useCORS: true,
                            });
                            const imgData = canvas.toDataURL("image/png");

                            // A3 paper size in pixels at 72 DPI
                            const A3_WIDTH = 841.89; // A3 width
                            const A3_HEIGHT = 1190.55; // A3 height
                            const margin = 40; // Margin for left and right

                            // Scale the content to fit within the A3 width (with left/right margins)
                            let scaledWidth = canvas.width;
                            let scaledHeight = canvas.height;

                            if (canvas.width > A3_WIDTH - margin * 2) {
                              // Scale down width if it exceeds A3 width minus margins
                              const scaleFactor =
                                (A3_WIDTH - margin * 2) / canvas.width;
                              scaledWidth = canvas.width * scaleFactor;
                              scaledHeight = canvas.height * scaleFactor;
                            }

                            const pdf = new jsPDF({
                              orientation: "portrait",
                              unit: "px",
                              format: [A3_WIDTH, A3_HEIGHT],
                            });

                            // Center horizontally, but align to the top vertically
                            const xOffset = (A3_WIDTH - scaledWidth) / 2; // Centered horizontally
                            const yOffset = 0; // No top margin

                            pdf.addImage(
                              imgData,
                              "PNG",
                              xOffset, // X offset (centered horizontally)
                              yOffset, // Y offset (no top margin)
                              scaledWidth, // Scaled width
                              scaledHeight // Scaled height
                            );

                            // Save the PDF
                            pdf.save(`${rowData.receiptNo || "invoice"}.pdf`);

                            // Clean up the temporary DOM
                            document.body.removeChild(tempDiv);
                          };

                          // Handle image load errors
                          imgElement.onerror = (error) => {
                            console.error("Failed to load the image:", error);
                          };
                        } else {
                          console.error(
                            "No img element found in the provided PDF URL."
                          );
                        }
                      } catch (error) {
                        console.error("Error generating PDF:", error);
                      }
                    } else {
                      const link = document.createElement("a");
                      link.href = rowData.pdfURL;
                      link.target = "_blank";
                      link.download = `${rowData.receiptNo || "invoice"}.pdf`;
                      link.click();
                    }
                  } else {
                    alert("No valid pdfURL provided.");
                  }
                }}
              >
                <FileDownloadIcon />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const payinColumns = [
    {
      title: "Week Nr",
      render: (rowData) => {
        return (
          <>{moment(rowData?.weekRange?.split(" - ")[0], "YYYYMMDD").week()}</>
        );
      },
    },
    {
      title: "Week",
      field: "weekRange",
      render: (rowData) => {
        return (
          <>
            {moment(rowData?.weekRange?.split(" - ")[0]).format("DD-MM-YYYY")} -{" "}
            {moment(rowData?.weekRange?.split(" - ")[1]).format("DD-MM-YYYY")}{" "}
          </>
        );
      },
    },
    {
      title: "Display Name",
      render: (rowData) => {
        return (
          <>
            {" "}
            {rowData?.client?.firstName} {rowData?.client?.lastName}
          </>
        );
      },
    },
    {
      title: "Company Name",
      field: "client.companyName",
    },
    {
      title: "Address",
      field: "client.companyAddress",
    },
    {
      title: "Bank Account holder Name",
      field: "client.bankAccountNumber",
    },
    {
      title: "Total Ride",
      field: "totalRide",
    },
    {
      title: "Total Amount",
      render: (rowData) => {
        return <>{formatCurrency(rowData?.currency, rowData?.totalPrice)}</>;
        // return (
        //   <>
        //     {getSymbolFromCurrency(rowData?.currency || "EUR")}
        //     {rowData?.totalPrice?.toFixed(2)}
        //   </>
        // );
      },
    },
    {
      title: "Action",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
      },
      sort: false,
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={() => {
                  setOpen(true);
                  let obj = {
                    isPaid: true,
                    amount: rowData.totalPrice,
                    weekStart: rowData.weekRange.split(" - ")[0],
                    weekEnd: rowData.weekRange.split(" - ")[1],
                    receiptNo: "Concept",
                    totalRide: rowData.totalRide,
                    userId: rowData.client.id,
                    orgId: user.id,
                    currency: rowData.currency
                  };
                  PreviewPayinPayment(obj, moment().utcOffset()).then((res) =>
                    setPdfString(res)
                  );
                }}
              >
                <VisibilityIcon style={{ cursor: "pointer" }} />
              </Button>
              <Button
                style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }}
                size="sm"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to approve this payment?"
                    )
                  ) {
                    setLoader(true);
                    let receiptNo = await generateReceiptNumber(
                      user,
                      GetPaidPaymentByYear
                    );
                    const bulkObj = {
                      ids: rowData.userBookingIds,
                      obj: {
                        receiptNo,
                      },
                    };

                    const bulkAdditionalObj = {
                      ids: rowData.userAdditionaBookingIds,
                      obj: {
                        receiptNo,
                      },
                    };

                    let obj = {
                      isPaid: false,
                      amount: rowData.totalPrice,
                      weekStart: rowData.weekRange.split(" - ")[0],
                      weekEnd: rowData.weekRange.split(" - ")[1],
                      receiptNo,
                      totalRide: rowData.totalRide,
                      vat: rowData.vat,
                      userId: rowData.client.id,
                      orgId: user.id,
                      bulkObj: bulkObj,
                      bulkAdditionalObj: bulkAdditionalObj,
                      currency: rowData.currency,
                    };
                    await CreatePayinPayment(obj);

                    let payinsData = await GetPayins(
                      user.id,
                      `${moment(selectedWeek.split(" - ")[0]).format(
                        "YYYY-MM-DD"
                      )} - ${moment(selectedWeek.split(" - ")[1]).format(
                        "YYYY-MM-DD"
                      )}`,
        
                    );
                    setData(payinsData);
                    setLoader(false);
                  }
                }}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "#0000ff8a",
                    backgroundColor: "white",
                  }}
                />
              </Button>
              <Button
                style={{ borderRadius: 25, minWidth: 40 }}
                size="sm"
                onClick={async () => {
                  setSelectedRow(rowData);
                  setPaymentSelected(true);
                  setClientId(rowData.client.id);
                }}
              >
                <EditIcon />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const SearchByID = async (isfromPayin = false) => {
    const data = await SearchByBookingId(searchTextById, moment().utcOffset());
    if (data) {
      if (data.payouts && !isfromPayin) {
        setType(data.payouts.status);
        setTypeStandbydata(data.payins); // {data, status}
        setData(data.payouts.data);
        if (data.payouts.status == "paid") {
          countTotalPage(data.payouts.data.length);
        } else if (data.payouts.status == "payouts") {
          setSelectedWeek(data.payouts.weekRange);
        }
      } else {
        setType(data.payins.status);
        setTypeStandbydata(null); // {data, status}
        setData(data.payins.data);
        if (
          data.payins.status == "paid" ||
          data.payins.status == "payins_paid"
        ) {
          countTotalPage(data.payins.data.length);
        } else if (data.payins.status == "payins") {
          setSelectedWeek(data.payins.weekRange);
        }
      }
    }
  };
  function removeSpecialCharacters(inputString) {
    return inputString.replace(/[^\w\s]/gi, "");
  }
  function removeSpaces(inputString) {
    return inputString.replace(/\s/g, "");
  }

  useEffect(() => {
    setSelectedRecords([]);
    setData((prev) =>
      prev.map((item) => ({
        ...item,
        tableData: { ...item.tableData, checked: false },
      }))
    );
  }, [currency, country, bank]);

  async function downloadSheet(bank, currency, countryPrefix) {
    bank = bank.toUpperCase();
    currency = currency.toUpperCase();
    countryPrefix = countryPrefix.toUpperCase();

    const sheet = `${bank}-${currency}-to-${countryPrefix}.xlsx`;

    const findDoc = (onBoardingDocs, name) => {
      const doc = onBoardingDocs.find((ob) => ob.networkDoc?.name == name);
      if (doc && doc.data) {
        const parsed = JSON.parse(doc.data);
        if (typeof parsed == "string") return JSON.parse(parsed);
        return parsed;
      } else return {};
    };

    const filteredData = [];
    const finalData = [];

    let startingRow;
    let startingCol;

    if (bank == "ING") {
      startingRow = 12;
      startingCol = "B";

      [...allSelected].map((item, index) => {
        if (item?.tableData?.checked) {
          const onboardingDocs = item.user.onboarding.onboardingDocs;
          // const { value: bankAccountHolderName } = findDoc(
          //   onboardingDocs,
          //   "Account Holder Name"
          // );
          // const { value: iban } = findDoc(onboardingDocs, "IBAN");

          const iban = removeSpaces(item.user.IBAN).trim();
          const bankAccountHolderName = removeSpecialCharacters(
            item.user?.bankAccountHolderName
          ).trim();

          filteredData.push({
            "Beneficiary Name": bankAccountHolderName,
            "Beneficiary IBAN": iban,
            Amount: parseFloat(item.amount),
            Description: `${item.invoiceNo}`,
            ID: `${item.invoiceNo}${new Date().getTime()}${index}`,
          });
          finalData.push({
            id: item.id,
          });
        }
      });
    } else if (bank == "REVOLUT" && countryPrefix == "NL") {
      startingRow = 2;
      startingCol = "A";

      [...allSelected].map((item, index) => {
        if (item?.tableData?.checked) {
          const onboardingDocs = item.user.onboarding.onboardingDocs;
          // const { value: iban } = findDoc(onboardingDocs, "IBAN");
          const iban = removeSpaces(item.user.IBAN).trim();
          const bankAccountHolderName = removeSpecialCharacters(
            item.user?.bankAccountHolderName
          ).trim();
          const { value: bic } = findDoc(onboardingDocs, "BIC");
          const { value: recepientBankCountry } = findDoc(
            onboardingDocs,
            "Bank Country"
          );
          // const { value: bankAccountHolderName } = findDoc(
          //   onboardingDocs,
          //   "Account Holder Name"
          // );
          const { value: accountType } = findDoc(
            onboardingDocs,
            "Account Type"
          );
          filteredData.push({
            Name: bankAccountHolderName,
            // "Recepient Type": accountType,
            "Recepient Type": "Business",
            IBAN: iban,
            BIC: bic,
            "Recipient Bank Country": recepientBankCountry,
            Currency: currency,
            Amount: parseFloat(item.amount),
            // "Payment Reference": `${
            //   item.invoiceNo
            // }${new Date().getTime()}${index}`,
            "Payment Reference": item.invoiceNo,
          });
          finalData.push({
            id: item.id,
          });
        }
      });
    } else if (bank == "REVOLUT" && countryPrefix == "GB") {
      startingRow = 2;
      startingCol = "A";

      [...allSelected].map((item, index) => {
        if (item?.tableData?.checked) {
          const bankAccountHolderName = removeSpecialCharacters(
            item.user?.bankAccountHolderName
          ).trim();
          const recepientBankCountry = removeSpecialCharacters(
            item.user?.network?.country
          ).trim();

          const onboardingDocs = item.user.onboarding.onboardingDocs;
          const { value: accountNumber } = findDoc(
            onboardingDocs,
            "Account Number"
          );
          const { value: sortCode } = findDoc(onboardingDocs, "Sort Code");
          // const { value: recepientBankCountry } = findDoc(
          //   onboardingDocs,
          //   "Bank Country"
          // );
          // const { value: bankAccountHolderName } = findDoc(
          //   onboardingDocs,
          //   "Account Holder Name"
          // );
          const { value: accountType } = findDoc(
            onboardingDocs,
            "Account Type"
          );

          filteredData.push({
            Name: bankAccountHolderName,
            // "Recepient Type": accountType,
            "Recepient Type": "Business",
            "Account Number": accountNumber,
            "Sort Code": sortCode,
            "Recipient Bank Country": recepientBankCountry,
            Currency: currency,
            Amount: parseFloat(item.amount),
            "Payment Reference": item.invoiceNo,
          });
          finalData.push({
            id: item.id,
          });
        }
      });
    }

    fetch(`/bank-templates/${sheet}`, {
      headers: {
        "Content-Type": "application/json",
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(async function (buff) {
        console.log(filteredData, "<====");
        await XlsxPopulate.fromDataAsync(buff).then((workbook) => {
          const worksheet = workbook.sheet(0);

          if (filteredData.length > 0) {
            filteredData.forEach((record, index) => {
              let col = startingCol;
              Object.keys(record).forEach((key) => {
                worksheet.cell(index + startingRow, col).value(record[key]);
                col = String.fromCharCode(col.charCodeAt(0) + 1); // Move to the next column
              });
            });

            if (bank == "ING") {
              worksheet.cell("C9").value(filteredData.length);
              worksheet.cell("C8").value(
                filteredData.reduce((sum, currentValue) => {
                  return sum + parseFloat(currentValue.Amount);
                }, 0)
              );
              worksheet
                .cell("C1")
                .value(
                  removeSpecialCharacters(user?.bankAccountHolderName || "")
                );
              worksheet.cell("C2").value(removeSpaces(user?.IBAN || ""));
              worksheet.cell("C4").value(moment().format("DD-M-YYYY"));
            }

            workbook.outputAsync().then(async (res) => {
              const file = new Blob([res], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const element = document.createElement("a");
              element.href = URL.createObjectURL(file);
              // element.download = `${new Date().toDateString()}.xlsx`;
              if (bank === "ING") {
                element.download = `${new Date().toDateString()}.xlsx`;
              } else {
                // element.download = `${new Date().toDateString()}.csv`;
                 // Generate CSV string from filteredData
                  let csvContent =
                  Object.keys(filteredData[0]).join(",") + "\n" + // Header row
                  filteredData.map((row) => Object.values(row).join(",")).join("\n"); // Data rows

                const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
                element.href = URL.createObjectURL(csvBlob);
                element.download = `${new Date().toDateString()}.csv`;
              }
              element.click();
              await XMLGenerated(finalData);
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                [...finalData].map((item) => {
                  if (item.id == element.id) {
                    data[index].sheetGenerated = true;
                    data[index].tableData.checked = false;
                  }
                });
              }
              setData([...data]);
              allSelected = [];
            });
          }
        });
      });
  }

  const tableRef = useRef();

  const getCurrentActiveShowingRows = () => {
    const tableInstance = tableRef.current;
    return tableInstance.state.data.filter((item) => !item.tableData.disabled);
  };

  const handleSelectionChange = (selected_rows, currentRow) => {
    let prevSelections = [...allSelected];
    const updateSelection = (item) => {
      const index = prevSelections.findIndex((prev) => prev.id === item.id);
      if (index === -1) {
        prevSelections.push(item);
      } else {
        prevSelections = prevSelections.filter(
          (_, itemIndex) => index !== itemIndex
        );
      }
    };

    if (currentRow) {
      updateSelection(currentRow);
    } else if (selected_rows.length > 0) {
      selected_rows.forEach(updateSelection);
    } else if (selected_rows.length === 0) {
      prevSelections = prevSelections.filter(
        (item) => !data.some((d) => d.id === item.id)
      );
    }

    const currentShowingActiveRows = getCurrentActiveShowingRows();
    const currentShowingSelectedRows = prevSelections.filter((item) =>
      data.some((d) => d.id === item.id)
    );

    if (!currentRow) {
      if (
        currentShowingActiveRows.length != currentShowingSelectedRows.length
      ) {
        currentShowingActiveRows.map((item) => {
          const index = prevSelections.findIndex((prev) => prev.id === item.id);
          prevSelections = prevSelections.filter(
            (_, itemIndex) => index !== itemIndex
          );
        });
      }
    }

    setData((prev) =>
      prev.map((item) => {
        const isChecked = prevSelections.some(
          (selection) => selection.id === item.id
        );
        return {
          ...item,
          tableData: { ...item.tableData, checked: isChecked },
        };
      })
    );

    allSelected = prevSelections;
    setSelectedRecords(prevSelections);
  };

  function setRowsFunction(rows) {
    if (allSelected?.length > 0 && rows?.length > 0) {
      const newRows = [];
      rows.map((record) => {
        const index = allSelected.findIndex((item) => item.id == record.id);
        if (index == -1) {
          newRows.push(record);
        } else {
          newRows.push({ ...record, tableData: allSelected[index].tableData });
        }
      });
      setData(newRows);
    } else {
      setData(rows);
    }
    console.log(
      "check if set rows function in case of search works or not",
      allSelected
    );
    setSelectedRecords(allSelected);
  }

  const generatePdfReport = async (scope = "PDF", currency) => {
    if (!paidFromDate || !paidTillDate) {
      alert("Please select both dates");
      return;
    }

    if (paidTillDate < paidFromDate) {
      alert("Till Date should be greater than From Date.");
      return;
    }

    const fromDate = moment(paidFromDate).format("YYYY-MM-DD");
    const tillDate = moment(paidTillDate).format("YYYY-MM-DD");

    try {
      setLoadingProcess({ type: scope, loading: true });
      const response = await GetPaidPaymentsByTimeRange(
        fromDate,
        tillDate,
        scope
      );

      if (
        scope === "INVOICE" &&
        response &&
        response?.paymentDetail?.length > 0
      ) {
        // v5
        let url = null;
        if (response?.org_image?.Body?.data) {
          // const buffer = new Uint8Array(response?.org_image.Body.data).buffer; // Represents the string "hello"
          // const blob = new Blob([buffer], { type: 'application/octet-stream' });
          // url = URL.createObjectURL(blob);
          const base64String = Buffer.from(
            response?.org_image?.Body,
            "base64"
          ).toString("base64");
          url = "data:image/jpeg;base64," + base64String;
        }
        const zip = new JSZip();
        response?.paymentDetail.forEach((payment, index) => {
          const getResult = PaymentInvoiceGenerate(
            payment,
            false,
            url,
            currency
          );
          zip.file(`invoice_${index + 1}.pdf`, getResult);
        });

        zip.generateAsync({ type: "blob" }).then((zipBlob) => {
          // Create an anchor element and simulate a click to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(zipBlob);
          link.download = "invoices.zip";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });

        alert(
          "Please be patient, as the download will take a little bit of time"
        );

        // v4
        // const zip = new JSZip();
        // response.forEach((pdfData, index) => {
        //   // Add each PDF data to the zip
        //   zip.file(`invoice_${index + 1}.pdf`, pdfData.data);
        // });

        // // Generate the zip file asynchronously
        // zip.generateAsync({ type: 'blob' })
        //   .then((zipBlob) => {
        //     // Create an anchor element and simulate a click to trigger the download
        //     const link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(zipBlob);
        //     link.download = 'invoices.zip';
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //   })
      } else if (scope === "PDF" && response && response.type === "Buffer") {
        // Convert the array to Blob and initiate the download
        const pdfData = new Uint8Array(response.data);
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${new Date().getTime()}.pdf`;
        link.click();
        // alert("Please be patient, as the download will take a little bit of time");
      } else {
        console.error("Invalid response format:", response);
        alert("Data not founded");
      }
    } catch (error) {
      console.error("Error generating PDF report:", error);
      alert("Failed to generate PDF report.");
    } finally {
      setLoadingProcess({ type: null, loading: false });
    }
  };

  return (
    <>
      {paymentSelected ? (
        type === "paid" || type === "payouts" ? (
          <>
            <SinglePaymentDetails
              selectedRow={selectedRow}
              selectedWeek={selectedWeek}
              driverId={driverId}
              setPaymentSelected={setPaymentSelected}
              loadData={loadData}
              allData={data}
              setLoader={setLoader}
            />
          </>
        ) : (
          <>
            <SinglePayInDetails
              selectedRow={selectedRow}
              selectedWeek={selectedWeek}
              driverId={driverId}
              clientId={clientId}
              setPaymentSelected={setPaymentSelected}
              setClientState={setClientId}
              type={"PAYIN"}
              loadData={loadData}
              allData={data}
              setLoader={setLoader}
            />
          </>
        )
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div
              style={{
                marginBottom: 60,
                backgroundColor: "red",
                float: "right",
                width: "100%",
              }}
            >
              <div style={{ position: "absolute", right: 45 }}>
                <Search style={{ display: "inline-block", marginRight: 10 }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Booking ID"
                    inputProps={{ "aria-label": "search" }}
                    value={searchTextById}
                    onChange={async (e) => {
                      if (e.target.value) {
                        setSearchTextById(e.target.value);
                      } else {
                        await getWeekRange();
                      }
                    }}
                  />
                </Search>
                <Button
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#2AABD5",
                    color: "#FFFFFF",
                    height: 40,
                    textTransform: "none",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    if (searchTextById.length > 0) {
                      SearchByID();
                    }
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
            <div className="mb-16">
              <StyledToggleButtonGroup
                value={type}
                exclusive
                onChange={(e, value) => {
                  if (typeStandbyData && typeStandbyData.status == value) {
                    setData(typeStandbyData?.data);
                    setSelectedWeek(typeStandbyData?.weekRange);
                    if (value !== null) {
                      setType(value);
                    }
                  } else {
                    setTypeStandbydata(null);
                    setSearchTextById("");
                    if (value !== null) {
                      setType(value);
                    }
                  }
                }}
              >
                <StyledToggleButton
                  disabled={loading}
                  key={0}
                  value={"payouts"}
                  style={
                    typeStandbyData?.status == "payouts"
                      ? { backgroundColor: "rgb(202 218 224)" }
                      : {}
                  }
                >
                  PayOuts
                </StyledToggleButton>
                <StyledToggleButton
                  disabled={loading}
                  key={1}
                  value={"paid"}
                  style={
                    typeStandbyData?.status == "paid"
                      ? { backgroundColor: "rgb(202 218 224)" }
                      : {}
                  }
                >
                  Paid
                </StyledToggleButton>
              </StyledToggleButtonGroup>

              <StyledToggleButtonGroup
                value={type}
                exclusive
                onChange={(e, value) => {
                  if (typeStandbyData && typeStandbyData?.status == value) {
                    setData(typeStandbyData?.data);
                    setSelectedWeek(typeStandbyData?.weekRange);
                    if (value !== null) {
                      setType(value);
                    }
                  } else {
                    setTypeStandbydata(null);
                    setSearchTextById("");
                    if (value !== null) {
                      setType(value);
                    }
                  }
                }}
              >
                <StyledToggleButton
                  disabled={loading}
                  key={0}
                  value={"payins"}
                  style={
                    typeStandbyData?.status == "payins"
                      ? { backgroundColor: "rgb(202 218 224)" }
                      : {}
                  }
                >
                  PayIns
                </StyledToggleButton>
                <StyledToggleButton
                  disabled={loading}
                  //key={1}
                  value={"pending"}
                  style={
                    typeStandbyData?.status == "pending"
                      ? { backgroundColor: "rgb(202 218 224)" }
                      : {}
                  }
                >
                  Pending
                </StyledToggleButton>
                <StyledToggleButton
                  disabled={loading}
                  //key={1}
                  value={"payins_paid"}
                  style={
                    typeStandbyData?.status == "payins_paid"
                      ? { backgroundColor: "rgb(202 218 224)" }
                      : {}
                  }
                >
                  Paid
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            </div>

            {(type === "paid" ||
              type === "pending" ||
              type === "payins_paid") && (
              <>
                <div style={{ marginRight: 20, float: "right" }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search… "
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </Search>
                </div>
              </>
            )}

            {type == "payouts" && (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 20 }}>
                  <div>
                    <label htmlFor="week-dropdown">Week:</label>
                    <select
                      id="week-dropdown"
                      value={selectedWeek}
                      onChange={async (event) => {
                        setSelectedWeek(event.target.value);
                        setSearchTextById("");
                        let payoutData = await GetPayouts(
                          user.id,
                          `${moment(event.target.value.split(" - ")[0]).format(
                            "YYYY-MM-DD"
                          )} - ${moment(
                            event.target.value.split(" - ")[1]
                          ).format("YYYY-MM-DD")}`,
                          moment().utcOffset()
                        );
                        setData(payoutData);
                      }}
                    >
                      {weeks &&
                        weeks.map((week) => {
                          const [startDate] = week.split(" - "); // Extract the start date of the week
                          const weekNumber = moment(
                            startDate,
                            "YYYY-MM-DD"
                          ).isoWeek(); // Get ISO week number
                          const weekYear = moment(
                            startDate,
                            "YYYY-MM-DD"
                          ).isoWeekYear(); // Get ISO week year

                          return (
                            <option value={week}>
                              Week {weekNumber} - {weekYear}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {type == "payins" && (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 20 }}>
                  <div>
                    <label htmlFor="week-dropdown">Week: </label>
                    <select
                      id="week-dropdown"
                      value={selectedWeek}
                      onChange={async (event) => {
                        setSelectedWeek(event.target.value);
                        setSearchTextById("");
                        let payinsData = await GetPayins(
                          user.id,
                          `${moment(event.target.value.split(" - ")[0]).format(
                            "YYYY-MM-DD"
                          )} - ${moment(
                            event.target.value.split(" - ")[1]
                          ).format("YYYY-MM-DD")}`,
                          moment().utcOffset(),
              
                        );
                        setData(payinsData);
                      }}
                    >
                      {weeks &&
                        weeks.map((week) => {
                          const [startDate] = week.split(" - "); // Extract the start date of the week
                          const weekNumber = moment(
                            startDate,
                            "YYYY-MM-DD"
                          ).isoWeek(); // Get ISO week number
                          const weekYear = moment(
                            startDate,
                            "YYYY-MM-DD"
                          ).isoWeekYear(); // Get ISO week year

                          return (
                            <option value={week}>
                              Week {weekNumber} - {weekYear}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          {type == "paid" && (
            <div>
              {/* From to till date get pdf  start*/}
              <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="From"
                    value={paidFromDate}
                    onChange={(newValue) => setPainFromDate(newValue)}
                    style={{ marginRight: "20px" }}
                  />
                  <DatePicker
                    label="Till"
                    value={paidTillDate}
                    onChange={(newValue) => setPaidTillDate(newValue)}
                    style={{ marginRight: "20px" }}
                  />

                  <Button
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#2AABD5",
                      color: "#FFFFFF",
                      height: 40,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      generatePdfReport("PDF", selectedCurrency);
                    }}
                  >
                    {" "}
                    {loadingProcess.type === "PDF" && loadingProcess.loading ? (
                      <CircularProgress size={18} />
                    ) : (
                      "Generate a report"
                    )}
                  </Button>

                  <Button
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#2AABD5",
                      color: "#FFFFFF",
                      height: 40,
                      textTransform: "none",
                      marginLeft: "20px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      generatePdfReport("INVOICE", selectedCurrency);
                    }}
                  >
                    {loadingProcess.type === "INVOICE" &&
                    loadingProcess.loading ? (
                      <CircularProgress size={18} />
                    ) : (
                      "Download All Invoices"
                    )}
                  </Button>
                  {/* {loadingProcess.type === "paid_invoice_download" && loadingProcess.loading} */}
                </MuiPickersUtilsProvider>
              </div>
              {/* From to till date get pdf  end*/}
              {total !== null && (
                <div style={{ float: "right", marginTop: 10 }}>
                  <StyledToggleButtonGroup
                    style={{ background: "white" }}
                    value={page}
                    exclusive
                  >
                    {page > 1 && (
                      <StyledToggleButton
                        onClick={async () => {
                          setPage(page - 1);
                          await onpageChange(page - 1);
                        }}
                        style={{ marginRight: 10 }}
                      >
                        <Typography
                          style={{ padding: "10 3 10 3" }}
                          component="h3"
                          align="center"
                        >
                          {" "}
                          Previous{" "}
                        </Typography>
                      </StyledToggleButton>
                    )}
                    {page !== 1 && (
                      <StyledToggleButton
                        onClick={async () => {
                          setPage(page - 1);
                          await onpageChange(page - 1);
                        }}
                        style={{ marginRight: 10 }}
                      >
                        <Typography
                          style={{ padding: "10 3 10 3" }}
                          component="h3"
                          align="center"
                        >
                          {" "}
                          {page - 1}
                        </Typography>
                      </StyledToggleButton>
                    )}
                    <StyledToggleButton
                      style={{ background: "white", marginRight: 10 }}
                    >
                      <Typography
                        style={{ padding: "10 3 10 3", color: "#2aabd5" }}
                        component="h3"
                        align="center"
                      >
                        {page}
                      </Typography>
                    </StyledToggleButton>

                    {total > page && (
                      <StyledToggleButton
                        style={{ marginRight: 10 }}
                        onClick={async () => {
                          setPage(page + 1);
                          await onpageChange(page + 1);
                        }}
                      >
                        <Typography
                          style={{ padding: "10 3 10 3" }}
                          component="h3"
                          align="center"
                        >
                          {page + 1}
                        </Typography>
                      </StyledToggleButton>
                    )}
                    {total > page && (
                      <StyledToggleButton
                        style={{ marginRight: 10 }}
                        onClick={async () => {
                          setPage(page + 1);
                          await onpageChange(page + 1);
                        }}
                      >
                        <Typography
                          style={{ padding: "10 3 10 3" }}
                          component="h3"
                          align="center"
                        >
                          Next
                        </Typography>
                      </StyledToggleButton>
                    )}
                  </StyledToggleButtonGroup>
                  <div style={{ float: "right" }}>
                    <ListSubheader style={{ marginTop: "-4px" }}>
                      <span style={{ fontWeight: "bold" }}>Total Count: </span>
                      <span>{count}</span>
                    </ListSubheader>
                  </div>
                </div>
              )}
            </div>
          )}

          {type == "paid" || type === "pending" || type === "payins_paid" ? (
            <>
              {type == "paid" ? (
                <>
                  <div style={{ flex: 1, direction: "row" }}>
                    <div style={{ marginTop: 50 }}>
                      <MaterialTable
                        tableRef={tableRef}
                        icons={tableIcons}
                        isLoading={loading}
                        onSelectionChange={handleSelectionChange}
                        columns={columnsPaid}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <Title
                                sheetOptionStates={{
                                  currency,
                                  country,
                                  bank,
                                  setCurrency,
                                  setCountry,
                                  setBank,
                                }}
                                downloadSheet={downloadSheet}
                                selectedRecords={selectedRecords}
                              />
                            </div>
                          ),
                        }}
                        data={data.filter(
                          (item) =>
                            currency == "all" || item.currency == currency
                        )}
                        options={{
                          actionsColumnIndex: -1,
                          paging: false,
                          search: false,
                          selection: true,
                          showTextRowsSelected: false,
                          selectionProps: (rowData) => {
                            // const check = checkPaymentInvoiceValidation(rowData,country,bank)
                            let check = false;
                            if (country === "GB") {
                              check = checkPaymentInvoiceValidation(
                                rowData,
                                country,
                                bank
                              );
                              if (!rowData?.user?.bankAccountHolderName) {
                                check = false;
                              }
                            } else {
                              check =
                                !!rowData?.user?.IBAN &&
                                !!rowData?.user?.bankAccountHolderName &&
                                rowData.amount > 0;
                            }
                            // const check =
                            //   !!rowData?.user?.IBAN &&
                            //   !!rowData?.user?.bankAccountHolderName &&
                            //   rowData.amount > 0;
                            rowData.tableData.disabled = !check;
                            return { disabled: !check };
                          },
                          rowStyle: (rowData) => ({
                            backgroundColor: rowData.sheetGenerated
                              ? "#D9EAD3"
                              : "#FFF",
                          }),
                          headerStyle: {
                            width: "100%",
                          },
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <MaterialTable
                  title={"PAYINS"}
                  icons={tableIcons}
                  isLoading={loading}
                  columns={columnsPayin}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  data={data}
                  options={{
                    search: true,
                  }}
                />
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  paddingRight: "30px",
                }}
              >
                <h2 className="MuiToolbar-root MuiToolbar-regular MTableToolbar-root-150 MuiToolbar-gutters">
                  {type === "payins" ? "PAYINS" : "PAYOUTS"}
                </h2>
                <NativeSelect
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value="all">Currency</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </NativeSelect>
              </div>
              <MaterialTable
                icons={tableIcons}
                isLoading={loading}
                columns={type === "payins" ? payinColumns : columns}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
                data={ data.filter(
                  (item) => currency == "all" || item.currency == currency
                )}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
              />
            </>
          )}
        </>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ width: "100%" }}
            >
              Preview invoice PDF
            </Typography>
            {/*<Button autoFocus color="inherit" onClick={handleDownloadPdf} disabled={!pdfString}>*/}
            {/*  Download*/}
            {/*</Button>*/}
          </Toolbar>
        </AppBar>
        <div style={{ margin: "auto", width: "50%", paddingTop: "4rem" }}>
          {pdfString ? (
            <>
              <Document
                file={pdfString}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                {Array.apply(null, Array(numPages)).map((page, index) => (
                  <Page pageNumber={++index} />
                ))}
              </Document>
            </>
          ) : (
            <p>Loading.....</p>
          )}
        </div>
      </Dialog>
      {loader && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
const mapState = (state) => ({
  user: state.userInfo.user,
  // data: state.bookings.api.response.data,
  // loading: state.bookings.api.loading,
  error: state.bookings.api.error,
});

const mapDispatch = {
  GetPaidPaymentByYear: ThunkPayment.GetPaidPaymentByYear,
  CreatePayinPayment: ThunkPayment.CreatePayinPayment,
  PaymentInvoiceDetail: ThunkPayment.PaymentInvoiceDetail,
  UpdatePayinPayment: ThunkPayment.UpdatePayinPayment,
  GetPayinPayments: ThunkPayment.GetPayinPayments,
  GetPayins: ThunksBooking.GetPayins,
  GetPayments: ThunkPayment.GetPayments,
  GetPaidPaymentsByTimeRange: ThunkPayment.GetPaidPaymentsByTimeRange,
  GetPaymentByYear: ThunkPayment.GetPaymentByYear,
  CreatePayment: ThunkPayment.CreatePayment,
  SearchByBookingId: ThunkPayment.SearchByBookingId,
  GetPayouts: ThunksBooking.GetPayouts,
  GetDriverEventsBybooking: ThunksBooking.GetDriverEventsBybooking,
  GetBookingdriver: ThunksBooking.GetBookingdriver,
  PreviewPayment: ThunkPayment.PreviewPayment,
  imageBase64: ThunkPayment.imageBase64,
  XMLGenerated: ThunkPayment.GenerateXML,
  PreviewPayinPayment: ThunkPayment.PreviewPayinPayment,
};

export default connect(mapState, mapDispatch)(PaymentDetails);
